import React from 'react';

const CustomContent = ({ post }) => {
  if (typeof window === 'undefined') {
    // If we're in a Node.js environment, return the content without modification
    return (
      <div
        className="blog-details-content second-comesup"
        dangerouslySetInnerHTML={{ __html: post.content }}
        style={{ maxWidth: '697px' }}
      />
    );
  }

  // Browser-specific code
  const content = post?.content;
  const customDivs = [
    `
    <div class="blog-ads-1">
      <img src="https://octet-gatsby.in2.cdn-alpha.com/wp-content/uploads/2024/08/blog.webp" alt="blog-ad-1"/>
      <div class="content-wrap">
        <h3>Tired of mediocre results?</h3>
        <ul>
          <li>From design to execution, we've got you covered.</li>
          <li>Get personalized plan to boost your traffic and conversions.</li>
        </ul>
        <a href="https://octet.design/contact-us/" target="_blank" class="btn-ad" rel="noopener">Contact Us</a>
      </div>
    </div>
    `,
    `
      <div class="blog-ads-2">
        <div class="content-wrap">
          <p>Powerful, Not Overpowering</p>
          <h3>Your project deserves the right power for a <strong>Thriving Business</strong></h3>
          <a href="https://octet.design/contact-us/" target="_blank" class="btn-ad" rel="noopener">Contact Us</a>
        </div>
      </div>
    `,
    `
      <div class="blog-ads-3">
        <div class="content-wrap">
          <p>Redefine Your Market Edge</p>
          <h3>Your business has the potential. <strong>Let’s make it happen.</strong></h3>
          <a href="https://octet.design/contact-us/" target="_blank" class="btn-ad" rel="noopener">Get Free Consultation</a>
        </div>
      </div>
    `,
  ];

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const elements = Array.from(doc.body.children);

  let h2Positions = [];
  let h3Positions = [];
  elements.forEach((element, index) => {
    if (element.tagName === 'H2') {
      h2Positions.push(index);
    }
    if (element.tagName === 'H3') {
      h3Positions.push(index);
    }
  });
  if (h2Positions.length > 0) {
    elements[h2Positions[0]].insertAdjacentHTML('beforebegin', customDivs[0]);
  }
  if (h2Positions.length > 0) {
    elements[h2Positions[h2Positions.length - 1]].insertAdjacentHTML('beforebegin', customDivs[2]);
  }
  let middlePosition = null;
  if (h2Positions.length > 2) {
    const middleIndex = Math.floor(h2Positions.length / 2);
    middlePosition = h2Positions[middleIndex];
  } else if (h3Positions.length > 2) {
    const middleIndex = Math.floor(h3Positions.length / 2);
    middlePosition = h3Positions[middleIndex];
  }
  if (middlePosition !== null) {
    elements[middlePosition].insertAdjacentHTML('beforebegin', customDivs[1]);
  }

  const updatedContent = doc.body.innerHTML;

  return (
    <div
        className="blog-details-content second-comesup"
        dangerouslySetInnerHTML={{ __html:updatedContent  }}
        style={{maxWidth: '697px'}}
    />
  );
};

export default CustomContent;
