import React, {useEffect} from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
// import KnowMoreSection from "../components/KnowMoreSection";
import { GatsbyImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import Linkedin from "../images/linkedin-blog"
import XBlog from "../images/x-blog"
import Mail from "../images/mail";
import LinkBlog from "../images/link";
import SubscriptionForm from "../components/contact/SubscriptionForm";
import CustomContent from "../components/CustomContent";


export default function BlogPost({ data }) {
  const post = data.allWpPost.edges[0].node;
  const similarPosts = data.similarQuery.edges;
  const dataCta = {
    title: "Inspire the next generation of designers",
    contactText: 'Write for us',
    contactLink: '/write-for-us'
  };
  // Date
  const dateStr = post.date;
  const dateTime = dayjs(dateStr);
  const formattedDate = dateTime.format("MMM D, YYYY");
  // Date
  const formattedDates = similarPosts.map((post) => {
    const dateStr = post.node.date;
    const dateTime = dayjs(dateStr);
    const formattedDate = dateTime.format("MMM D, YYYY");
    return formattedDate;
  });
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        document.body.style.overflowX = 'unset';
      } else {
        document.body.style.overflowX = '';
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const shareToLinkedIn = () => {
    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`https://octet.design/${post.slug}/`)}`;
    window.open(linkedInShareUrl, '_blank', 'noopener,noreferrer');
  };
  const shareToTwitter = () => {
    const text = "Check out this blog!";
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://octet.design/${post.slug}/`)}&text=${encodeURIComponent(text)}`;
    window.open(twitterShareUrl, '_blank', 'noopener,noreferrer');
  };
  const shareViaEmail = () => {
    const subject = "Check out this blog!";
    const body = `I found this blog and thought you might like it: https://octet.design/${post.slug}/`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };
  const copyToClipboard = () => {
    const currentPageUrl = `https://octet.design/${post.slug}/`;
    navigator.clipboard.writeText(currentPageUrl).then(() => {
      alert('URL copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  return (
    <>
      <Seo title={post.seo.title} description={post.seo.metaDesc} image={post.seo?.opengraphImage?.link ? post.seo.opengraphImage.link : ''} date={post.date} modified={post.modified} />
      <Helmet>
        <script type="application/ld+json">
          {post.hometest.hometest}
        </script>
      </Helmet>
      <Layout>
        <section className="blog-details lg:pt-[120px] md:pt-[100px] md:pb-[100px] py-[80px] fade-in-animation">
          <div className="container">
            <div className="max-w-750 mx-auto">
              <div className="pl-50px">
                <p className="text-[#2A2A2A] first-comesup mb-12px font-medium md:leading-[22px] md:text-[15px] text-[12px] tracking-[.05em] block leading-[20px] link-text-para">{formattedDate}</p>
                <h1 className="mb-12px first-comesup text-[32px] md:text-[38px] lg-text-44px leading-[1.5] font-bold text-[#121212]">
                  {post.title}
                </h1>
                <p className="text-[#2A2A2A] first-comesup info font-medium md:leading-[22px] md:text-[15px] text-[12px] tracking-[.05em] block leading-[20px] link-text-para">
                  {
                    post.categories.nodes[0].slug !== 'uncategorized' ?
                    <>
                      <a
                        href={`/blogs/${post.categories.nodes[0].slug}/`}
                        className=""
                      >
                        {post.categories.nodes[0].name}
                      </a>{" "}{" "}
                      • {" "}
                    </> : ''
                  }
                  <a
                    href={`/author/${post.author.node.slug === 'sitemanager' ? 'aakash-jethwani' : post.author.node.slug}/`}
                    className=""
                  > {post.author.node.name}</a>  {" "}
                  • {post.seo.readingTime === 0 ? 15 : post.seo.readingTime} Mins reading time 
                </p>
              </div>
              <div className="gap-[25px] max-w-750 flex relative flex-col-reverse lg:flex-row">
                <div className="flex gap-11px soc-icons">
                  <div onClick={shareToLinkedIn}>
                    <Linkedin/>
                  </div>
                  <div onClick={shareToTwitter}>
                    <XBlog />
                  </div>
                  <div onClick={shareViaEmail}>
                    <Mail />
                  </div>
                  <div onClick={copyToClipboard}>
                    <LinkBlog />
                  </div>
                </div>
                {/* <div
                  className="blog-details-content second-comesup"
                  dangerouslySetInnerHTML={{ __html:updatedContent  }}
                  style={{maxWidth: '697px'}}
                /> */}
                <CustomContent post={post} />
              </div>
            </div>
          </div>
        </section>
        <section className="lg:pb-[120px] md:pb-[100px] pb-[80px]">
          <div className="container">
            <div className="max-w-750 mx-auto">
              <div className="h-[276px] flex-col justify-start items-start gap-[30px] flex">
                <div className="self-stretch justify-start items-center gap-[20px] flex">
                  <div className="text-[#2a2a2a] text-[20px] font-medium capitalize leading-[36px] tracking-wide font-heading">Written By</div>
                  <div className="grow shrink basis-0 h-[0px] border border-black"></div>
                </div>
                <div className="self-stretch justify-start items-start md:gap-[20px] gap-[15px] flex flex-col md:flex-row">
                  <img className="rounded-[50%] author-img" src="https://octet-gatsby.in2.cdn-alpha.com/wp-content/uploads/2024/01/image-1.png" />
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-[12px] flex">
                    <div className="self-stretch flex-col justify-center items-start gap-[8px] md:gap-[12px] flex">
                      <div className="self-stretch flex-col justify-start items-start gap-[4px] flex">
                        <h2 className="self-stretch text-[#2a2a2a] md:text-[30px] text-[24px] font-medium capitalize leading-[1.33] tracking-wide font-heading">Aakash Jethwani</h2>
                        <p className="self-stretch text-[#2a2a2a] lg:text-[18px] md:text-[16px] text-[15px] font-medium leading-[1.66] tracking-tight">Consulting Enterprise and SaaS Tech Companies</p>
                        <Link href="/author/aakash-jethwani/" className="lg:text-[18px] md:text-[16px] text-[15px] font-medium underline hover:no-underline w-fit leading-[30px] tracking-tight" style={{color: '#0050ec'}}>80+ Stories</Link>
                      </div>
                    </div>
                    <p className="self-stretch h-[90px] text-[#2a2a2a] lg:text-[18px] md:text-[16px] text-[15px] font-normal lg:leading-[30px] leading-[normal] tracking-tight">A design leader known for creating and offering pixel-perfect design by striking a balance between design and technology to his clients while also managing his team and business.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* test */}
        <Cta data={dataCta} gap container />
        <section className="lg:py-[200px] md:py-[120px] py-[80px]">
          <div className="container">
            <h2 className="text-[30px] font-medium md:text-[40px] lg:text-[50px] font-heading md:leading-[1.3] leading-[normal] md:mb-[50px] mb-[40px]">
              Related Blogs
            </h2>
            <div
              className={`flex flex-col lg:gap-[10px] blogs-list`}
            >
              {similarPosts.map(({ node }, index) => (
                <div key={index}>
                  {node.categories.nodes.map((category) => (
                    <Link
                      key={category.slug}
                      to={`/blogs/${category.slug}/`}
                      className="text-[15px] leading-[24px] tracking-[.75px] underline mb-[20px] underline-offset-4 hover:no-underline inline-block"
                    >
                      {category.name}
                    </Link>
                  ))}
                  <Link
                    to={`/${node.slug}/`}
                    className="lg:pb-[70px] blog-link-tag mb-[70px] md:pb-[60px] pb-[80px] blog-div flex gap-[30px] lg:gap-[0] justify-between md:items-center flex-col md:flex-row"
                    key={index}
                  >
                    <div className="max-w-[600px] md:w-[80%] w-[100%]">
                      <h2 className="md:text-[34px] text-unset lg:leading-[56px] text-[26px] leading-[36px] font-semibold mb-[10px]">
                        {node.title}
                      </h2>
                      <div
                        className="eliple ibm lg:line-clamp-2 line-clamp-4 lg:text-[18px] md:leading-[30px] md:tracking-[.03em] md:text-[16px] text-[15px] leading-[26px] tracking-[.02em] mb-[35px]"
                        dangerouslySetInnerHTML={{ __html: node.excerpt }}
                      ></div>
                      <p className="text-[#2A2A2A] info font-medium md:leading-[22px] md:text-[14px] text-[12px] tracking-[.05em] block leading-[20px] link-text-para">
                        {formattedDates[index]} • By{" "}
                        {node.author.node.name}
                      </p>
                    </div>
                    <div className="md:w-[327px] w-[100%] blog-list-image">
                      {node.featuredImage && node.featuredImage.node && (
                        <>
                          <GatsbyImage
                            image={node?.featuredImage?.node?.gatsbyImage}
                            alt={
                              node.featuredImage.node.altText
                                ? node.featuredImage.node.altText
                                : "blogs"
                            }
                            title={node.featuredImage.node.title}
                          />
                        </>
                      )}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* <KnowMoreSection nin="Blogs" Include="services" /> */}
        <SubscriptionForm />
      </Layout>
    </>
  );
}
export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          date
          modified
          slug
          categories {
            nodes {
              name
              link
              slug
            }
          }
          hometest {
            hometest
          }
          seo {
            metaDesc
            title
            readingTime
            opengraphImage {
              link
            }
          }
          author {
            node {
              name
              slug
            }
          }
        }
      }
    }
    similarQuery: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Tutorials" } } } }
        title: { nin: "6 Easy Steps to Conduct Usability Testing in UX Design" }
      }
      limit: 2
    ) {
      edges {
        node {
          title
          excerpt
          slug
          date
          seo {
            readingTime
          }
          featuredImage {
            node {
              gatsbyImage(formats: WEBP, width: 400, placeholder: BLURRED)
              altText
              title
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          author {
            node {
              name
            }
          }
        }
      }
    }
  }
`;
